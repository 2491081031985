import {Typography} from "@material-ui/core";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  appTitle: {
    marginTop: "13px"
  },
}));

export default function TitleComponent() {
  const classes = useStyles();
  return (
    <>
      <Typography
        className={classes.appTitle}
        variant="h6"
      >
        SODISO: Socially Distant Socializing
      </Typography>
    </>
  );
}