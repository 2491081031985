import {useHistory} from "react-router-dom";
import {AppBar, Box, Button, Toolbar, Typography} from "@material-ui/core";
import React from "react";
import SwipeableTemporaryDrawer from "./components/Drawer/DrawerComponent";
import {makeStyles} from "@material-ui/core/styles";
import {ArrowBackIos, ArrowForwardIos} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  toolBar: {
    display: "flex",
    justifyContent: "space-between"
  },
}));

export default function TopBarComponent(props: {children?: any}) {
  const classes = useStyles();
  return (
    <AppBar position="static">
      <Toolbar className={classes.toolBar}>
        <SwipeableTemporaryDrawer/>
        {props.children}
      </Toolbar>
    </AppBar>
  )
}

export function NewEventButton() {
  let history = useHistory();

  return (
    <Box m={2}>
      <Button
        color="secondary"
        endIcon={<ArrowForwardIos/>}
        onClick={() => history.push("/new-event")}
      >
        <Typography variant="body1">Neuer Event</Typography>
      </Button>
    </Box>
  );
}

export function BackToOverview() {
  let history = useHistory();

  return (
    <Box m={2}>
      <Button
        color="secondary"
        startIcon={<ArrowBackIos/>}
        onClick={() => history.push("/")}
      >
        <Typography variant="body1">Zur Übersicht</Typography>
      </Button>
    </Box>
  );
}

