import React from 'react';
import './App.css';
import OverviewComponent from "./components/Overview/OverviewComponent";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import TitleComponent from "./components/TitleComponent";
import AboutComponent from "./components/Drawer/DrawerMenuComponents/AboutComponent";
import IdeasComponent from "./components/Drawer/DrawerMenuComponents/IdeasComponent";
import HowToComponent from "./components/Drawer/DrawerMenuComponents/HowToComponent";
import CreateNewEventComponent from "./components/CreateNewEventComponent/CreateNewEventComponent";
import TopBarComponent, {BackToOverview, NewEventButton} from "./TopBarComponent";

import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#534bae',
      main: '#1a237e',
      dark: '#000051',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#ffffff',
      main: '#fafafa',
      dark: '#c7c7c7',
      contrastText: '#000000',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route path="/new-event">
            <div className="App">
              <TopBarComponent>
                <BackToOverview/>
              </TopBarComponent>
              <TitleComponent/>
              <CreateNewEventComponent/>
            </div>
          </Route>
          <Route path="/how-to">
            <div className="App">
              <TopBarComponent>
                <BackToOverview/>
              </TopBarComponent>
              <TitleComponent/>
              <HowToComponent/>
            </div>
          </Route>
          <Route path="/ideas">
            <div className="App">
              <TopBarComponent>
                <BackToOverview/>
              </TopBarComponent>
              <TitleComponent/>
              <IdeasComponent/>
            </div>
          </Route>
          <Route path="/about">
            <div className="App">
              <TopBarComponent>
                <BackToOverview/>
              </TopBarComponent>
              <TitleComponent/>
              <AboutComponent/>
            </div>
          </Route>
          <Route path="/">
            <div className="App">
              <TopBarComponent>
                <NewEventButton/>
              </TopBarComponent>
              <TitleComponent/>
              <OverviewComponent/>
            </div>
          </Route>
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
