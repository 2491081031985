import React from 'react';
import {Typography} from "@material-ui/core";

export default function HowToComponent() {
  return (
    <>
      <Typography>
        In diesem App geht es darum Leute zusammen zu bringen die sich durch Corona eigentlich nicht mehr Kennenlernen würden.



        Erstellen eines Neuen Events:
        Ihr könnt einfach oben rechts auf den Button "Neuer Event" klicken. Das einzige, dass ihr angeben müsst ist ein Titel und einen Link zum Event.
        Danach kommt ihr auf die Hauptseite des Events und den Link könnt ihr auch weiterschicken.


        Erstellen einens Neuen Events auf anderen Plattformen:

        Ihr konnt z.B. auf hostpoint.ch/meet ein neues online Meeting erstellen. Den Link dieses Meeting könnt ihr dann hier dem Event beifügeb, damit
        andere dem Event beitreten können.
      </Typography>
    </>
  )
}