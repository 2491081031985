import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';
import {Box, Link} from "@material-ui/core";
import EventModel from "../../models/EventModel";

const useStyles = makeStyles((theme) => ({
  text: {
    textAlign: "left",
    fontWeight: 300,
    whiteSpace: "pre-wrap"
  },
  textSubtitle: {
    fontWeight: 400,
    textAlign: 'left'
  },
  textSubtitleAfterText: {
    marginTop: "1.5em"
  },
  eventBox: {
    marginBottom: '10px'
  },
  timeInterval: {
    color: 'gray',
    fontSize: '10px'
  }
}));


export default function EventListItemComponent(props: { event: EventModel }) {
  const event = props.event;
  const classes = useStyles();

  return (
    <Box className={classes.eventBox}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon fontSize="large"/>}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="left"
            textAlign="left">
            <Typography variant="subtitle2">{event.title}</Typography>
            <TimeComponent event={event}/>
          </Box>
        </AccordionSummary>
        <Divider/>
        <AccordionDetails>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="left"
          >
            <Typography
              variant="body2"
              className={classes.textSubtitle}
            >
              Beschreibung:
            </Typography>
            <Typography
              variant="body2"
              className={classes.text}
            >
              {event.description}
            </Typography>
            <MeetingLink event={event}/>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

function TimeComponent(props: { event: EventModel }) {
  const event = props.event;
  const classes = useStyles();
  return (
    <div className={classes.timeInterval}>
      {event.startDate.toLocaleDateString('de-CH')}
      &nbsp;um&nbsp;
      {event.startDate.toLocaleTimeString('de-CH').substring(0, 5)} Uhr
      ({event.duration} Stunden)
    </div>
  )
}

function MeetingLink(props: { event: EventModel }) {
  const event = props.event;
  const classes = useStyles();
  return (
    <>
      <Typography variant="body2" className={`${classes.textSubtitle} ${classes.textSubtitleAfterText}`}>
        Link:
      </Typography>
      <Typography variant="body2" className={classes.text}>
        <Link href={event.link}>
          {event.link}
        </Link>
      </Typography>
    </>
  );
}