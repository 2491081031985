import {Box, Chip, TextField} from "@material-ui/core";
import React, {SetStateAction, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import EventModelList from "../../models/EventModelList";

const useStyles = makeStyles((theme) => ({
  search: {
    flexBasis: '50%',
  },
  chips: {
    marginLeft: "10px"
  }
}));

export default function SearchComponent(props: {events: EventModelList,
  setFilteredEvents: React.Dispatch<SetStateAction<EventModelList>>}){
  const classes = useStyles();
  const events = props.events;
  const setFilteredEvents = props.setFilteredEvents;
  const [todaysEventsOnly, setTodaysEventsOnly] = useState(true);
  const [runningEventsOnly, setRunningEventsOnly] = useState(false);
  const [search, setSearch] = useState('');


  return (
    <>
      <Box flexDirection="column" m={2}>
        <Box>
          <TextField
            id="outlined-basic"
            label="Suche"
            variant="outlined"
            fullWidth
            value={search}
            onChange={(event) => {
              setSearch(event.target.value);
              const filteredEvents = filterEvents(events, event.target.value, todaysEventsOnly, runningEventsOnly)
              setFilteredEvents(filteredEvents);
            }}
          />
        </Box>
      </Box>
      <Box flexDirection="column" m={2}>
        <Chip
          color="primary"
          label={'Heute'}
          variant={todaysEventsOnly ? undefined : "outlined"}
          onClick={() => {
            const value = !todaysEventsOnly;
            setTodaysEventsOnly(value);
            const filteredEvents = filterEvents(events, search, value, runningEventsOnly)
            setFilteredEvents(filteredEvents);
          }}
          className={classes.chips}
        />
        <Chip
          color="primary"
          label={'Laufende'}
          variant={runningEventsOnly ? undefined : "outlined"}
          onClick={() => {
            const value = !runningEventsOnly;
            setRunningEventsOnly(value);
            const filteredEvents = filterEvents(events, search, todaysEventsOnly, value)
            setFilteredEvents(filteredEvents);
          }}
          className={classes.chips}
        />
      </Box>
    </>
  )
}

function filterEvents(events: EventModelList,
                      search: string,
                      todaysEventsOnly: boolean,
                      runningEventsOnly: boolean) {
  let filteredEvents = events.filterByTitle(search)
  if (todaysEventsOnly) {
    filteredEvents = filteredEvents.getTodaysEvents();
  }
  if (runningEventsOnly) {
    filteredEvents = filteredEvents.getCurrentEvents();
  }
  return filteredEvents;
}