import React from "react";
import EventListItemComponent from "./EventListItemComponent";
import EventModelList from "../../models/EventModelList";

export default function EventListComponent(props: { eventList: EventModelList }) {
  const eventList = props.eventList.events.map(e => <EventListItemComponent event={e}/>)
  return (
    <>
      {eventList}
    </>
  )
}