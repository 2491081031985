import React from 'react';
import {withFormik, FormikProps, FormikErrors, Form} from 'formik';
import {Box, Button, TextField} from "@material-ui/core";
import EventDateTimePickersComponent from "./EventDateTimePickersComponent";
import ValidationService from "../../services/ValidationService";
import { BackendClient, EventResource, IEventResource } from '../../services/BackendClient';
import {useHistory} from "react-router-dom";

interface FormValues {
  title: string;
  description: string;
  link: string;
  startDate: Date;
  startTime: Date;
  duration: number;
}

interface OtherProps {
}

function ButtonRow(props: {isSubmitting: boolean}) {
  const { isSubmitting } = props;
  return (
    <Box m={2}>
      <Button
        type="submit"
        disabled={isSubmitting}
        color="primary"
        variant="outlined"
      >
        Neuer Event erstellen
      </Button>
    </Box>
  );
}

const InnerForm = (props: OtherProps & FormikProps<FormValues>) => {

  const {touched, errors, isSubmitting, handleChange, values, handleBlur, setFieldValue} = props;
  return (
    <Form>
      <Box m={2}>
        <TextField
          id="title"
          error={!!errors.title && !!touched.title}
          label="Titel"
          helperText={!!errors.title && !!touched.title ? errors.title : "Ein Titel für dein Meeting"}
          variant="outlined"
          fullWidth
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.title}
        />
      </Box>
      <Box m={2}>
        <TextField
          id="description"
          label="Beschreibung"
          multiline
          rows={4}
          helperText="Worüber willst du Sprechen oder was willst du machen?"
          variant="outlined"
          fullWidth
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.description}
        />
      </Box>
      <Box m={2}>
        <TextField
          id="link"
          label="Meeting Link"
          error={!!errors.link}
          helperText={!!errors.link ? errors.link : "Füge den Link für das Meeting hier ein"}
          variant="outlined"
          fullWidth
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.link}
        />
      </Box>
      <EventDateTimePickersComponent
        values={values}
        setFieldValue={setFieldValue}
      />
      <Box m={2}>
        <TextField
          id="duration"
          label="Dauer in Stunden"
          type="number"
          value={values.duration}
          onChange={handleChange}
          onBlur={handleBlur}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
        />
      </Box>
      <ButtonRow isSubmitting={isSubmitting}/>
    </Form>
  );
};

interface AdditionalFormProps {
  history: any
}

const CreateNewEventForm = withFormik<AdditionalFormProps, FormValues>({
  mapPropsToValues: props => {
    return {
      title: '',
      description: '',
      link: '',
      startDate: new Date(),
      startTime: new Date(),
      duration: 0
    };
  },

  validate: (values: FormValues) => {
    let errors: FormikErrors<FormValues> = {};
    if (!values.title) {
      errors.title = 'Titel erforderlich';
    }
    if (values.link) {
      if (ValidationService.containsDisabledCharacters(values.link)){
        errors.link = 'Url enthält aus Sicherheitsgründen deaktivierte Zeichen.'
      }
      if (ValidationService.isSwinelistedDomain(values.link)){
        errors.link = 'Du Lustmolch, so sache machemer da nöd :p'
        return errors;
      }
      if(!ValidationService.isWhitelistedDomain(values.link)){
        errors.link = 'Domain nicht in der Whitelist enthalten.'
      }
    }
    return errors;
  },

  handleSubmit: (values, { props }) => {
    const backendClient = new BackendClient("https://sodiso-api.azurewebsites.net");
    const endDate = new Date(values.startDate);
    endDate.setHours(values.startDate.getHours() + values.duration);
    const resourceData: IEventResource ={
      description: values.description,
      title: values.title,
      startDate: values.startDate,
      endDate: endDate,
      link: values.link
    };

    backendClient.addEvent(new EventResource(resourceData))
      .then(props.history.push('/'));
  },
})(InnerForm);

const CreateNewEventComponent = () => {
  const history = useHistory()
  return (
    <CreateNewEventForm history={history}/>
  )
}

export default CreateNewEventComponent;