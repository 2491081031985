import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import EmojiObjectsTwoToneIcon from '@material-ui/icons/EmojiObjectsTwoTone';
import HelpOutlineTwoToneIcon from '@material-ui/icons/HelpOutlineTwoTone';
import InfoTwoToneIcon from '@material-ui/icons/InfoTwoTone';
import MenuIcon from "@material-ui/icons/Menu";
import EventIcon from '@material-ui/icons/Event';
import {IconButton} from "@material-ui/core";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

export default function SwipeableTemporaryDrawer() {
  const classes = useStyles();
  const [isExtended, setState] = React.useState(false);
  let history = useHistory();

  const toggleDrawer = () => () => {
    setState(!isExtended);
  };

  const list = (anchor: String) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer()}
      onKeyDown={toggleDrawer()}
    >
      <List>
			<ListItem
				button
				key="Events"
				onClick={() => history.push("/")}
			>
				<ListItemIcon><EventIcon /></ListItemIcon>
				<ListItemText primary="Events" />
			</ListItem>
        <ListItem
          button
          key={'How To'}
          onClick={() => history.push("/how-to")}
        >
          <ListItemIcon><HelpOutlineTwoToneIcon/></ListItemIcon>
          <ListItemText primary={'How To'} />
        </ListItem>
        <ListItem
          button
          key={'Ideas'}
          onClick={() => history.push("/ideas")}
        >
          <ListItemIcon><EmojiObjectsTwoToneIcon/></ListItemIcon>
          <ListItemText primary={'Ideas'} />
        </ListItem>
        <ListItem
          button
          key={'About'}
          onClick={() => history.push("/about")}
        >
          <ListItemIcon><InfoTwoToneIcon/></ListItemIcon>
          <ListItemText primary={'About'} />
        </ListItem>
      </List>
    </div>
  );

  return (
    <div>
      <React.Fragment key={'left'}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer()}
        >
          <MenuIcon />
        </IconButton>
        <SwipeableDrawer
          anchor={'left'}
          open={isExtended}
          onClose={toggleDrawer()}
          onOpen={toggleDrawer()}
        >
          {list('left')}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}