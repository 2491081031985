export default class ValidationService {

  static whiteList = ['zoom.us', 'hostpoint.ch', 'discord.com']

  static swineList = ['xnxx.com','xvideos.com','chatroulette.com']

  static isWhitelistedDomain(link: String): boolean {
    return link.startsWith('https://')
        && ValidationService.whiteList.includes(this.extractDomain(link));
  }

  static isSwinelistedDomain(link: String): boolean {
    return link.startsWith('https://')
        && ValidationService.swineList.includes(this.extractDomain(link));
  }

  private static extractDomain(link: String) {
    let domainParts = link.substring(8).split('/')[0].split('.');
    let length = domainParts.length;
    let tld = domainParts[length - 1];
    let domain = domainParts[length - 2];
    return domain + '.' + tld;
  }

  static containsDisabledCharacters(url: string) {
    return !url.match(/^[A-z0-9\-/:.?=]*$/g)
  }
}