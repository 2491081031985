export default class EventModel {
  private readonly _title: string;
  private readonly _link: string;
  private readonly _description: string;
  private readonly _startDate: Date;
  private readonly _endDate: Date;

  constructor(title: string, link: string, description: string, startDate: Date, endDate: Date) {
    this._title = title;
    this._link = link;
    this._description = description;
    this._startDate = startDate;
    this._endDate = endDate;
  }

  get title(): string {
    return this._title;
  }

  get link(): string {
    return this._link;
  }

  get description(): string {
    return this._description;
  }

  get startDate(): Date {
    return this._startDate;
  }

  get duration(): number {
    return Math.abs(this._startDate.getTime() - this._endDate.getTime()) / (60 * 60 * 1000);
  }

  get endDate() {
    return this._endDate;
  }

  isToday(): Boolean {
    const today = new Date();
    return this._startDate.getUTCFullYear() === today.getUTCFullYear()
      && this._startDate.getMonth() === today.getMonth()
      && this._startDate.getDate() === today.getDate();
  }

  isRunning(): Boolean {
    const today = new Date()
    return this._startDate <= today && today <= this.endDate;
  }
}