import React, {useEffect, useMemo, useState} from 'react';
import {Box, Paper} from "@material-ui/core";
import EventListComponent from "./EventListComponent";
import SearchComponent from "./SearchComponent";
import { BackendClient } from '../../services/BackendClient';
import EventModel from '../../models/EventModel';
import EventModelList from '../../models/EventModelList';

export default function OverviewComponent() {
  const backendClient = useMemo(() => new BackendClient("https://sodiso-api.azurewebsites.net"), []);
  const [events, setEvents] = useState<EventModelList>(new EventModelList([]));
  const [filteredEvents, setFilteredEvents] = useState<EventModelList>(new EventModelList([]));
  
  useEffect(() => {
    const fetchData = async () => {
      const eventResources = await backendClient.getAllEvents();
      const events = eventResources.map(er => new EventModel(er.title!, er.link!, er.description!, er.startDate!, er.endDate!));
      const modelList = new EventModelList(events);
      setEvents(modelList);
    };
    fetchData();
  }, [backendClient]);

  useEffect(() => {
    setFilteredEvents(events.getTodaysEvents());
  }, [events])

  return (
    <Paper elevation={0}>
      <SearchComponent
        events={events}
        setFilteredEvents={setFilteredEvents}
      />
      <Box m={2}>
        <EventListComponent eventList={filteredEvents}/>
      </Box>
    </Paper>
  );
}