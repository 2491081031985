import React from 'react';
import {KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {Box} from "@material-ui/core";
import {FormikValues} from "formik";

export default function EventDateTimePickersComponent(props: {values: FormikValues, setFieldValue: any}){
  const values = props.values;
  const setFieldValue = props.setFieldValue;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Box m={2}>
        <KeyboardDatePicker
          margin="normal"
          id="startDate"
          label="Datum"
          format="dd/MM/yyyy"
          value={values.startDate}
          onChange={(date) => setFieldValue("startDate", date)}
          fullWidth
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </Box>
      <Box m={2}>
        <KeyboardTimePicker
          margin="normal"
          id="startTime"
          label="Von"
          value={values.startDate}
          onChange={(date) => setFieldValue("startDate", date)}
          fullWidth
          KeyboardButtonProps={{
            'aria-label': 'change time',
          }}
        />
      </Box>
    </MuiPickersUtilsProvider>
  )
}