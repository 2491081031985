import { EventResource } from "../services/BackendClient";
import EventModel from "./EventModel";

export default class EventModelList {

  public constructor(private _events: EventModel[]) { }

  get events() {
    return this._events;
  }

  getCurrentEvents() {
    return new EventModelList(this._events.filter((event) => event.isRunning()));
  }

  getTodaysEvents() {
    return new EventModelList(this._events.filter((event) => event.isToday()));
  }

  filterByTitle(title: string) {
    return new EventModelList(this._events.filter((event) => event.title.toLowerCase().startsWith(title.toLowerCase())));
  }
}