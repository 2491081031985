import React from 'react';
import {Typography} from "@material-ui/core";

export default function AboutComponent() {
  return (
    <>
      <Typography variant="h6">Entstehung</Typography>
      <Typography variant="body1">
        In diesem App geht es darum Leute zusammen zu Bringen die sich unter gegeben Umständen ansonsten nicht mehr
        Kennenleren würden.

        Wir gehen auch davon aus, dass Corona noch länger andauern wird und daher haben wir uns entschieden,
        diese Webapp zu Programmieren.
      </Typography>

      <Typography variant="h6">Contributors</Typography>

      <Typography variant="h6">Kontakt</Typography>
    </>
  )
}